<template>

    <div class="hero-section">
        <div class="container">
        <div class="row">
            <div class="col-12 text-center text-white">
                <div class="row introtext">
                <h2 class="titreintro">Votre coin de paradis,</h2>
                <div class="col-3"></div>
                <div class="col-md-6">
                    au bord du lac d'Annecy pour déguster des cocktails délicieux et vivre des instants conviviaux. Reconnu pour son ambiance décontractée et son service amical, notre établissement offre des petits déjeuners face au lac et des soirées animées. Avec une équipe dynamique et des événements réguliers, c'est l'endroit idéal pour se détendre et s'amuser en bonne compagnie.
                </div>
                    <div class="col-3"></div>
                </div>
            </div>
        </div>
        </div>
    </div>
    <!-- <span class="phrases"></span> -->
    <div class="container my-5">
  
      <div class="row mb-4" style="margin-top:5vh;">
        <div class="col-6 col-md-3 mb-4" v-for="photo in photos" :key="photo.id">
          <div class="tile">
            <img :src="getImagePath(photo.fileName)" class="img-fluid" :alt="photo.alt">
            <div class="tile-text" :style="{ backgroundColor: photo.overlayColor }">
              <img :src="getIconPath(photo.icon)" :alt="photo.alt" class="tile-icon">
              <h4 v-html="photo.title"></h4>
              <span class="content">{{ photo.description }}</span>
            </div>
          </div>
        </div>
      </div>
        <div class="row">
            <div class="col-3"></div>
            <div class="col-6 d-flex justify-content-center">
                <router-link class="btn-stylized2" to="/menu">MenuS</router-link>
            </div>
            <div class="col-3"></div>
        </div>

  
      <div class="row mb-4" style="margin-top:5vh;">
        <div class="col-12">
          <iframe src="https://cdn.lightwidget.com/widgets/ce592f7f15055389a61fc9da5738e1b4.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;"></iframe>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'App',
    data() {
      return {
        hover: 'no',
        photos: [
          { id: 1,
            fileName: "2.jpg",
            images: [
              "2.jpg",
              "3.jpg",
              "9.jpg",
              "2.jpg",
            ],
            icon: "sun",
            alt: "Terrasse au bord du lac d'Annecy",
            title: "Terrasse<br>au Lac",
            description: "Découvrez le charme unique de notre terrasse ensoleillée au bord du lac d'Annecy. Un lieu convivial près des terrains de bouliste, parfaitement accessible via la piste cyclable. Reposez-vous sous la fraîcheur des arbres.",
            overlayColor: "rgba(228, 228, 184, 0.9)"
          },
          { id: 2,
            fileName: "24.jpg",
            images: [
              "33.jpg",
              "24.jpg",
              "5.jpg",
              "24.jpg",
            ],
            icon: "breakfast",
            alt: "Petits déjeuners au Cabanon",
            title: "Matins Gourmands",
            description: "Commencez votre journée en beauté avec nos petits déjeuners gourmands. Savourez une tasse de café aromatique et une sélection de viennoiseries fraîches tout en profitant de la vue imprenable sur le lac.",
            overlayColor: "rgba(202, 220, 224, 0.9)"
          },
          { id: 3,
            fileName: "20.jpg",
            images: [
              "20.jpg",
              "26.jpg",
              "20.jpg",
              "16.jpg",
            ],
            icon: "food",
            alt: "Foodtrucks au Cabanon",
            title: "Plaisirs Culinaires",
            description: "Rejoignez-nous pour un déjeuner ou un dîner festif avec une rotation alléchante de foodtrucks. Du BBQ aux crêpes, en passant par les burgers, il y a toujours quelque chose de nouveau et excitant à goûter chez nous.",
            overlayColor: "rgba(243, 207, 151, 0.9)"
          },
          { id: 4,
            fileName: "19.jpg",
            images: [
              "19.jpg",
              "21.jpg",
              "31.jpg",
              "19.jpg",
            ],
            icon: "dj",
            alt: "Soirées avec DJ au Cabanon",
            title: "Nuits Enchantées",
            description: "L'ambiance se transforme à la tombée de la nuit. Nos soirées avec DJ sont l'essence d'une ambiance décontractée. Laissez-vous emporter par la musique et dansez sous les étoiles lors de nos événements nocturnes.",
            overlayColor: "rgba(227, 189, 185, 0.9)"
          }
        ],
      };
    },
    methods: {
      getImagePath(fileName) {
        // Vous pouvez aussi choisir d'utiliser directement les chemins relatifs si vous préférez.
        return require('@/assets/gallery/' + fileName);
      },
      getIconPath(fileName) {
        // Vous pouvez aussi choisir d'utiliser directement les chemins relatifs si vous préférez.
        return require('@/assets/' + fileName + '.svg');
      },
    }
  };
  </script>
  
 