<template>
  <div id="app">
    <!-- <div class="phrases"></div> -->
    <div class="container">
  <!-- Title -->
  <div class="header row">
    <div class="col-12 col-sm-8">
      <a href="/"><h1 class="display-1 neonText">Le Cabanon</h1></a>
    </div>
    <!-- Instagram -->
    <a class="col-12 col-sm-4 d-flex justify-content-end align-items-center" href="https://www.instagram.com/lecabanon.annecy/" target="_blank" rel="noopener noreferrer">
      <img src="@/assets/instagram.svg" class="img-icon" alt="Instagram">
      <h6 class="font-weight-light">Planning & événements <b>@lecabanon.annecy</b></h6>
    </a>
  </div>

  </div>
    <!-- Tu peux ajouter une navbar ici aussi -->
    <router-view />
  
  <!-- Footer -->
  <footer class="footer">
    <div class="container text-center">
      <p class="footer-link">
        <span class="neonText" style="color:white; font-size:4em;">Le Cabanon</span><br>
        <a href="http://www.lecabanonannecy.fr" target="_blank" rel="noopener noreferrer">www.lecabanonannecy.fr</a> |
        © 2023-2025 Tous droits réservés. | Développé par <a href="http://www.thecompagnie.eu" target="_blank" rel="noopener noreferrer">TheCompagnie</a>
      </p>
    </div>
  </footer>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style>
  
@media (max-width: 1324px) {
  .text{
    font-size: 0.8em !important;
    line-height: 1em !important;
  }
}

@media (max-width: 1024px) {
  .text{
    font-size: 1em !important;
    line-height: 1em !important;
  }
}

@media (max-width: 1024px) {
  .image-container .overlay{
    padding: 0.5em !important;
  }
  .text{
    line-height: 0.8em;
  }
  .content{
    font-size: 0.7em !important;
  }
  .header .col-12{
    padding: 0em !important;
  }
  h1{
    font-size: 5em !important;
  }
  h6{
    font-size: 0.6rem !important;
  }
  h4{
    font-size: 1rem !important;
  }
  img.img-icon{
    width:2em;
    padding: 0.3em !important;
  }
  .introtext h2.titreintro {
    font-size: 3rem !important;
  }
  .col-sm-6{
    font-size: 0.8rem !important;
  }
  h1.herotitre{
    font-size: 3rem !important;
  }
  .introtext{
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
  .btn-stylized {
    font-size: 0.8rem !important;
  }
  .col-12{
    padding: 1em;
  }
}

@font-face {
    font-family: 'Adventures Unlimited';
    src: local('Adventures Unlimited Script Bold'), local('Adventures-Unlimited-Script-Bold'),
        url('./assets/fonts/AdventuresUnlimited-ScriptBold.woff2') format('woff2'),
        url('./assets/fonts/AdventuresUnlimited-ScriptBold.woff') format('woff'),
        url('./assets/fonts/AdventuresUnlimited-ScriptBold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

#app{
  font-family: 'Montserrat';
  font-size: 1em;
}

.phrases{
  background-image: url('@/assets/phrases.svg');
  position: absolute;
  width: 100vw;
  height: 100vw;
  z-index: -10;
  opacity: 0.5;
  pointer-events: none;
}

.container {
  max-width: 1200px;
}

a{
  text-decoration: none !important;
  color: black !important;
}

.font-weight-light{
  font-weight: lighter;
}

.display-1 {
  font-size: 8rem;
}

.header{
  padding: 1em;
}

.img-icon {
  width: 5em;
  padding: 1em;
}

.neonText {
  color: #000;
  font-family: 'Adventures Unlimited', cursive;
  cursor: pointer;
  width: fit-content;
}
.neonText:hover {
  color: #fff;
  text-shadow:
    0 0 2px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #FFC107,
    0 0 82px #FFC107,
    0 0 92px #FFC107,
    0 0 102px #FFC107,
    0 0 151px #FFC107;
}

/* Styles pour l'effet de survol sur les images */
.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 1em;
  cursor: pointer;
  transition: 0.3s ease;
}

.img-fluid {
  width: 100%;
  height: auto;
  transition: 0.3s ease;
}

.image-container:hover .img-fluid {
  transform: scale(1.05);
}

.overlay {
  transition: 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  opacity: 1;
  justify-content: center;
  padding: 2em !important;
}

/* .image-container:hover .overlay {
  opacity: 0;
} */

.text {
  text-align: left;
  transition: opacity 0.5s ease;
  opacity: 1;
  color: black;
  font-size: 1em;
  line-height: 1.4em;
}

.text h4{
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  margin-bottom: 0.5em;
  font-size: 2em;
}

.text .content{
  text-align: justify !important;
}

.image-container:hover .text {
  opacity: 1;
}

.footer {
  background-color: #000;
  color: #fff;
  padding: 20px 0;
  font-size: 0.8em;
}

.footer a {
  color: #fff !important;
  text-decoration: none;
  transition: all 0.3s ease;
}

.footer a:hover {
  color: #FFC107;
}

.btn-stylized {
  margin-top: 2em;
  background-color: transparent; /* Fond transparent */
  color: white !important; /* Texte blanc */
  border: 2px solid white; /* Bordure blanche */
  border-radius: 30px; /* Bords arrondis */
  padding: 10px 20px; /* Padding intérieur */
  font-size: 16px; /* Taille du texte */
  font-weight: bold; /* Texte en gras */
  text-transform: uppercase; /* Texte en majuscules */
  cursor: pointer; /* Curseur en forme de main */
  transition: all 0.3s ease; /* Transition douce pour les effets de survol */
  text-decoration: none; /* Pas de soulignement du texte */
  display: inline-block; /* Permet au bouton de s'ajuster au contenu */
}

.btn-stylized2 {
  margin-top: 2em;
  background-color: transparent; /* Fond transparent */
  color: black !important; /* Texte blanc */
  border: 2px solid black; /* Bordure blanche */
  border-radius: 30px; /* Bords arrondis */
  padding: 10px 20px; /* Padding intérieur */
  font-size: 20px; /* Taille du texte */
  font-weight: bold; /* Texte en gras */
  text-transform: uppercase; /* Texte en majuscules */
  cursor: pointer; /* Curseur en forme de main */
  transition: all 0.3s ease; /* Transition douce pour les effets de survol */
  text-decoration: none; /* Pas de soulignement du texte */
  display: inline-block; /* Permet au bouton de s'ajuster au contenu */
}


.btn-stylized2:hover, .btn-stylized2:focus {
  background-color: black; /* Changement de fond au survol */
  color: #fff !important; /* Changement de la couleur de texte au survol */
  text-decoration: none; /* Pas de soulignement du texte au survol */
}

/* Ajustements pour accessibilité et focus visuel */
.btn-stylized2:focus-visible {
  outline: 3px solid #aaa; /* Contour pour l'accessibilité */
  outline-offset: 3px; /* Espace autour du contour */
}

.img-icon-hover{
  position: absolute;
  width:100%;
  padding: 35%;
  align-items: center;
  transition: all 0.3s ease;
}

.btn-stylized:hover, .btn-stylized:focus {
  background-color: white; /* Changement de fond au survol */
  color: #333 !important; /* Changement de la couleur de texte au survol */
  text-decoration: none; /* Pas de soulignement du texte au survol */
}

/* Ajustements pour accessibilité et focus visuel */
.btn-stylized:focus-visible {
  outline: 3px solid #aaa; /* Contour pour l'accessibilité */
  outline-offset: 3px; /* Espace autour du contour */
}

.hero-section {
  background-image: url('@/assets/gallery/23.jpg'); /* Remplacez par le chemin réel de votre image */
  background-size: cover;
  background-position: top;
  min-height: 40vh; /* Hauteur de la section hero */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
  margin-bottom: 2em;
}

.hero-img2 {
  background-image: url('@/assets/gallery/7.jpg') !important;
  background-position: center;
  border-radius: 0;
}

.hero-section::after {
  content: '';
  background: rgba(0, 0, 0, 0.4); /* Overlay sombre pour une meilleure lecture du texte */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.hero-img2::after {
border-radius: 0;
}

.hero-img4 {
  background-image: url('@/assets/gallery/lamaison.jpg') !important;
  border-radius: 0;
  background-position: center;
}

.hero-img4::after {
border-radius: 0;
}

.herotitre{
  font-family: 'Adventures Unlimited', cursive;
  font-size: 6em !important;
}

.container {
  position: relative;
  z-index: 2; /* S'assurer que le texte apparaît au-dessus de l'overlay */
}
.introtext{
  padding-top: 5em;
  padding-bottom: 6em;
}
.introtext h2{
  font-family: 'Adventures Unlimited', cursive;
  font-size: 4em !important;
}
.introtext .col-6{
  text-align: justify;
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.tile {
  position: relative;
  border-radius: 1em;
  overflow: hidden;
  background-color: #fff;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tile:hover {
  transform: scale(1.05);
}
.tile img {
  width: 100%;
  height: auto;
  max-height: 300px; /* Adjust the max-height for better responsiveness */
  object-fit: cover;
}
.tile-text {
  padding: 1em !important;
  text-align: center;
}
.tile-icon {
  width: 4em !important;
  margin-bottom: 1em;
}
.tile h4 {
  font-size: 1.5em !important;
  margin-bottom: 0.5em !important;
  color: white !important;
  font-weight: bold;
  text-transform: uppercase;
}
.tile .content {
  font-size: 1em !important;
  font-size: 0.9em;
  text-align: justify !important;
}

@media (max-width: 1024px) {
  .mb-4{
    margin-bottom: 0rem !important;
    margin-top: 0rem !important;
  }
  .tile {
    margin-bottom: 0rem;
  }
  .tile img {
    max-height: 250px; /* Adjust the max-height for smaller screens */
  }
  .tile-text {
    padding: 0.5em !important;
  }
  .tile-icon {
    width: 3em !important;
  }
  .tile h4 {
    font-size: 1.2em !important;
  }
  .tile .content {
    font-size: 0.8em !important;
  }
}

</style>
