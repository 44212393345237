<template>
    <div class="hero-section hero-img2" style="margin-bottom: 0 !important; border-radius: 0;">
        <div class="container">
        <div class="row">
            <div class="col-12 text-center text-white">
                <div class="row introtext">
                <h2 class="titreintro">Découvrez notre sélection</h2>
                <div class="col-3"></div>
                <div class="col-md-6">
                    Notre large selection devrait vous enchanter!<br>
                    <a href="./menucabanon.pdf" target="_blank" class="btn-stylized" style="margin-right: 1em;">Notre carte <img src="@/assets/openshare.svg" alt="Carte" style="height:1em; margin-left: 0.2em;"></a>
                </div>
                    <div class="col-3"></div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <div class="hero-section hero-img4" style="margin-bottom: 0 !important; border-radius: 0;">
        <div class="container">
        <div class="row">
            <div class="col-12 text-center text-white">
                <div class="row introtext">
                <h2 class="titreintro">La soirée continue à <b>La Maison</b></h2>
                <div class="col-3"></div>
                <div class="col-md-6">
                    La soirée ne fait que commencer! Rendez-vous à La Maison jusqu'à 2h!<br>
                    <a href="https://www.google.com/maps/dir/Le+Cabanon,+Avenue+du+Petit+Port,+Annecy/La+Maison+2A+Quai+Perri%C3%A8re+74000+Annecy/@45.9017115,6.1341387,16z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x478b8f75df714d4f:0xb14b44412753a26!2m2!1d6.151114!2d45.9050673!1m5!1m1!1s0x478b8f5769080219:0x6b5228e1596a0235!2m2!1d6.1285137!2d45.8981734!3e1?entry=ttu&g_ep=EgoyMDI1MDMyNC4wIKXMDSoJLDEwMjExNDUzSAFQAw%3D%3D" target="_blank" class="btn-stylized">Itinéraire <img src="@/assets/openshare.svg" alt="Crêpes" style="height:1em; margin-left: 0.2em;"></a>
                </div>
                    <div class="col-3"></div>
                </div>
            </div>
        </div>
    </div>
    </div>
    
</template>
  
  <script>
  export default {
    name: 'MenuPage'
  }
  </script>
  
  <style scoped>
  h1 {
    font-weight: bold;
  }
  </style>
  